import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITool, IViewerProvider } from 'ngx-dicom';
import { IDicomConfig, ToolModeEnum } from 'ngx-dicom';
import { RadiologyMri } from 'src/app/Models/radiologymri.model';
import { MriService } from 'src/app/services/mri.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-mri',
  templateUrl: './view-mri.component.html',
  styleUrls: ['./view-mri.component.css'],
})
export class ViewMriComponent {
  imageUrl: RadiologyMri[] = [];

  hideDicom: boolean = true;
  config: IDicomConfig = {
    // fileUrl: this.imageUrl,
    fileUrl: '',
    // fileUrl: "http://localhost:4200/assets/0002.DCM",
    tools: [
      {
        name: 'DragProbe',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Eraser',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Magnify',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'StackScrollMouseWheel',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Active,
      },
      {
        name: 'Rotate',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Pan',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'ZoomMouseWheel',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Length',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Angle',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'FreehandRoi',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Wwwc',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
    ],
    classList: 'canvas-container',
  };

  viewerProvider: IViewerProvider | undefined;

  exportStateToJson(): void {
    this.download(
      JSON.stringify(
        this.viewerProvider?.cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState()
      ),
      'toolsState.json',
      'application/json'
    );
  }

  saveAs(): void {
    this.viewerProvider?.cornerstoneTools.SaveAs(
      this.viewerProvider?.element,
      'screenshot.png'
    );
  }

  activateTool(name: string): void {
    const foundTool = this.config.tools?.find((tool) => tool.name === name);
    if (foundTool && this.viewerProvider) {
      this.viewerProvider.cornerstoneTools['setToolActive'](
        name,
        foundTool.options
      );
    }
  }

  isActive(tool: ITool): boolean {
    return this.viewerProvider?.cornerstoneTools.isToolActiveForElement(
      this.viewerProvider?.element,
      tool.name
    );
  }

  private download(content: any, fileName: string, contentType: string) {
    const a = document.createElement('a');
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private mriService: MriService,
    private patientService: PatientService
  ) {}
  id = 0;
  ngOnInit() {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatientMri(this.id);
  }

  loadUrl(image: string): void {
    this.config.fileUrl = image;
    this.hideDicom = false;
    setTimeout(() => (this.hideDicom = true));
  }

  getPatientMri(id) {
    this.patientService.getRadiologyMRI(id).subscribe((res) => {
      this.imageUrl = res.RadiologyMris.map((item) => {
        const imgDate = new Date(item.createdDate);
        item.createdDate = `${imgDate.getDate()}.${imgDate.getMonth()}.${imgDate.getFullYear()}`;
        return item;
      });
      //  this.config.fileUrl = res.RadiologyMris[1].MriImage;
      //  this.hideDicom = false;
      //  setTimeout(
      //   ()=> this.hideDicom= true
      //  )

      // console.log('Consfig: %o', this.config)
      // console.log('Consfig: %o', this.config)
    });
  }

  delete(id) {
    this.mriService.deleteRadiologyMri(id).subscribe((res) => {
      this.getPatientMri(this.id);
    });
  }
}
