<div class="pb-5">

  <!-- <div class="content">
        <div class="sidebar mat-elevation"> -->
  <!-- <div class="header">
            <img src="../../../assets/admin.png" class="image" alt="">

            <h4 class="text-white ml-2">Patient Details</h4>
           </div> -->
  <div class="toolbar mat-elevation-z6">

    <button mat-raised-button class="backG" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

    <mat-menu class="ItemS" #crt="matMenu">

      <div class="ItemS">
        <mat-icon mat-list-icon>undo</mat-icon>
        <div class="mx-2" [routerLink]="['/nurseDash/']"> Nurse Dashboard</div>
      </div>
      <div class="ItemS " (click)="showItem='19'" [ngClass]="{'active': showItem =='19'}">
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <div class="mx-2">Back</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS" (click)="showItem='9'" [ngClass]="{'active': showItem =='9'}">
        <!-- <mat-icon mat-list-icon>comment</mat-icon> -->
        <i class="fa-solid fa-pills"></i>
        <div class="mx-2"> Prescription</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <hr>


      <hr>


      <div class="ItemS" (click)="showItem='79'" [ngClass]="{'active': showItem =='79'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> Blood Sugar</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='80'" [ngClass]="{'active': showItem =='80'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2"> Blood Sugar Chart</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <hr>
      <!-- <hr> -->
      <!-- <div class="ItemS" (click)="showItem='54'" [ngClass]="{'active': showItem =='54'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <i class="fa-solid fa-pills"></i>
        <div class="mx-2"> Add + View Prescription</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <hr> -->


      <div class="ItemS" (click)="showItem='42'" [ngClass]="{'active': showItem =='42'}">
        <mat-icon mat-list-icon>chat_apps_script</mat-icon>
        <div class="mx-2">Communication-Assessments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <!-- <div class="ItemS" (click)="showItem='61'" [ngClass]="{'active': showItem =='61'}">
          <mat-icon mat-list-icon>visibility</mat-icon>
          <div class="mx-2"> Disclosure and Consent</div>
          <mat-icon>navigate_next</mat-icon>
        </div> -->
      <div class="ItemS" (click)="showItem='50'" [ngClass]="{'active': showItem =='50'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> Doctor Initial Evaluation</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS " (click)="showItem='46'" [ngClass]="{'active': showItem =='46'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">Doctor Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='18'" [ngClass]="{'active': showItem =='18'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">Dr Rounds Book</div>
        <mat-icon>navigate_next</mat-icon>
      </div>



      <div class="ItemS" (click)="showItem='45'" [ngClass]="{'active': showItem =='45'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">Nurse Note/Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
        <mat-icon mat-list-icon>nature_people</mat-icon>
        <div class="mx-2">Patient Summary</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>

      <div class="ItemS" (click)="showItem='53'" [ngClass]="{'active': showItem =='53'}">
        <mat-icon mat-list-icon>water_pump</mat-icon>
        <div class="mx-2">Lab Chart</div>

        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='39'" [ngClass]="{'active': showItem =='39'}">
        <mat-icon mat-list-icon>water_pump</mat-icon>
        <div class="mx-2">View Lab Order</div>

        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='11'" [ngClass]="{'active': showItem =='11'}">
        <mat-icon mat-list-icon>web</mat-icon>
        <div class="mx-2">View Lab-Reports</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='64'" [ngClass]="{'active': showItem =='64'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> View-Procedure-Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS " (click)="showItem='57'" [ngClass]="{'active': showItem =='57'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">View Radiology Orders</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='31'" [ngClass]="{'active': showItem =='31'}">
        <mat-icon mat-list-icon>report</mat-icon>
        <div class="mx-2">Radiology-Reports</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS " (click)="showItem='23'" [ngClass]="{'active': showItem =='23'}">
        <mat-icon mat-list-icon>report</mat-icon>
        <div class="mx-2">Vitals Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='30'" [ngClass]="{'active': showItem =='23'}">
        <mat-icon mat-list-icon>report</mat-icon>
        <div class="mx-2">Vital Chart</div>
        <mat-icon>navigate_next</mat-icon>
      </div>









      <hr>





      <hr>

      <div class="ItemS" (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Appointment</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <!-- <div class="ItemS" (click)="showItem='54'" [ngClass]="{'active': showItem =='54'}">
          <mat-icon mat-list-icon>add</mat-icon>
          <div class="mx-2">New Blood Transfusion Consent</div>
          <mat-icon>navigate_next</mat-icon>
        </div> -->

      <div class="ItemS" (click)="showItem='40'" [ngClass]="{'active': showItem =='40'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Communication-Assessment</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <!-- <div class="ItemS" (click)="showItem='60'" [ngClass]="{'active': showItem =='60'}">
          <mat-icon mat-list-icon>add</mat-icon>
          <div class="mx-2">New Disclosure & Consent</div>
          <mat-icon>navigate_next</mat-icon>
        </div> -->



      <div class="ItemS" (click)="showItem='38'" [ngClass]="{'active': showItem =='38'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Lab-Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='10'" [ngClass]="{'active': showItem =='10'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Lab-Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='44'" [ngClass]="{'active': showItem =='44'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Nurse Note/Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>



      <div class="ItemS" (click)="showItem='56'" [ngClass]="{'active': showItem =='56'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Radiology Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='22'" [ngClass]="{'active': showItem =='22'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Vitals Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>



      <div class="ItemS" (click)="showItem='4'" [ngClass]="{'active': showItem =='4'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Visit</div>
        <mat-icon>navigate_next</mat-icon>
      </div>



      <!-- <div class="ItemS" (click)="showItem='8'" [ngClass]="{'active': showItem =='8'}">
               <mat-icon mat-list-icon>add</mat-icon>
               <div class="mx-2">New Script</div>
               <mat-icon>navigate_next</mat-icon>
             </div> -->







      <hr>
      <div class="ItemS" (click)="showItem='13'" [ngClass]="{'active': showItem =='13'}">
        <mat-icon mat-list-icon>add_alert</mat-icon>
        <div class="mx-2"> Allergy History</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <!-- <div class="ItemS" (click)="showItem='55'" [ngClass]="{'active': showItem =='55'}">
          <mat-icon mat-list-icon>visibility</mat-icon>
          <div class="mx-2"> Blood Transfusion Consent</div>
          <mat-icon>navigate_next</mat-icon>
        </div> -->









      <div class="ItemS" (click)="showItem='15'" [ngClass]="{'active': showItem =='15'}">
        <mat-icon mat-list-icon>content_cut</mat-icon>
        <div class="mx-2">Surgical History</div>
        <mat-icon>navigate_next</mat-icon>
      </div>



      <hr>
      <div class="ItemS" (click)="showItem='66'" [ngClass]="{'active': showItem =='66'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2">Upload MRI</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='67'" [ngClass]="{'active': showItem =='67'}">
        <mat-icon mat-list-icon>view_array</mat-icon>
        <div class="mx-2">View MRI images</div>
        <mat-icon>navigate_next</mat-icon>
      </div>









      <div class="ItemS">
        <mat-icon mat-list-icon>undo</mat-icon>
        <div class="mx-2" [routerLink]="['/nurseDash/']"> Nurse Dashboard</div>
        <!-- <mat-icon>navigate_next</mat-icon> -->
      </div>
    </mat-menu>


    <h2 class="text-white text-center "><img class="nurseimg" src="{{nurse?.NurseImage}}" alt=""> {{nurse?.NurseName}}
      <span class="text-white text-center"> - Id: {{nurse?.NurseId}}</span>
    </h2>

  </div>

  <!-- <div class="ItemS  " >
             <mat-icon mat-list-icon>add</mat-icon>
             <div class="mx-2">New Appointment</div>
             <mat-icon>navigate_next</mat-icon>
           </div>
           <div class="ItemS  " >
             <mat-icon mat-list-icon>visibility</mat-icon>
             <div class="mx-2">Medical Records</div>
             <mat-icon>navigate_next</mat-icon>
           </div>
            -->



  <!-- 
         </div> -->
  <!-- <div class="main">
     <div class="toolbar mat-elevation-z6" ></div> -->

  <span [ngSwitch]="showItem">

    <p *ngSwitchCase="'1'">
      <app-patientsummary></app-patientsummary>
    </p>
    <p *ngSwitchCase="'2'">
      <app-add-emergency-contact></app-add-emergency-contact>
    </p>

    <p *ngSwitchCase="'3'">
      <app-add-patient-insurance></app-add-patient-insurance>
    </p>
    <p *ngSwitchCase="'4'">
      <app-add-patient-visit-by-nurse></app-add-patient-visit-by-nurse>
    </p>
    <p *ngSwitchCase="'5'">
      <app-add-appointment></app-add-appointment>
    </p>
    <p *ngSwitchCase="'6'">
      <app-notepad></app-notepad>
    </p>
    <p *ngSwitchCase="'7'">
      <app-vew-doctor-note></app-vew-doctor-note>
    </p>
    <p *ngSwitchCase="'8'">
      <app-add-prescription-by-nurse></app-add-prescription-by-nurse>
    </p>
    <p *ngSwitchCase="'9'">
      <app-view-prescription-by-nurse></app-view-prescription-by-nurse>
    </p>
    <p *ngSwitchCase="'10'">
      <app-add-labreport></app-add-labreport>
    </p>
    <p *ngSwitchCase="'11'">
      <app-view-labreport></app-view-labreport>
    </p>
    <p *ngSwitchCase="'12'">
      <app-add-allergy></app-add-allergy>
    </p>
    <p *ngSwitchCase="'13'">
      <app-view-allergy></app-view-allergy>
    </p>

    <p *ngSwitchCase="'14'">
      <app-add-surgical-history></app-add-surgical-history>
    </p>
    <p *ngSwitchCase="'15'">
      <app-view-surgical-history></app-view-surgical-history>
    </p>
    <p *ngSwitchCase="'16'">
      <app-add-invoice></app-add-invoice>
    </p>
    <p *ngSwitchCase="'17'">
      <app-view-invoice></app-view-invoice>
    </p>
    <p *ngSwitchCase="'18'">
      <app-vew-doctor-note></app-vew-doctor-note>
    </p>
    <p *ngSwitchCase="'19'">
      <app-patientdetails></app-patientdetails>
    </p>





    <p *ngSwitchCase="'22'">
      <app-add-nursereport></app-add-nursereport>
    </p>

    <p *ngSwitchCase="'23'">
      <app-view-patient-nursereport></app-view-patient-nursereport>
    </p>





    <!-- 
      <p *ngSwitchCase="'28'">
        <app-add-nursing-assesment></app-add-nursing-assesment>
      </p> -->

    <!-- <p *ngSwitchCase="'29'">
      <app-view-nursing-assessment-plan></app-view-nursing-assessment-plan>
    </p> -->
    <p *ngSwitchCase="'30'">
      <app-vitals-chart></app-vitals-chart>
    </p>
    <p *ngSwitchCase="'31'">
      <app-view-radiology-report></app-view-radiology-report>
    </p>







    <p *ngSwitchCase="'38'">
      <app-add-lab-order></app-add-lab-order>
    </p>
    <p *ngSwitchCase="'39'">
      <app-view-lab-order></app-view-lab-order>
    </p>

    <p *ngSwitchCase="'56'">
      <app-add-radiology-order></app-add-radiology-order>
    </p>
    <p *ngSwitchCase="'57'">
      <app-view-radiology-order></app-view-radiology-order>
    </p>





    <p *ngSwitchCase="'40'">
      <app-add-communication-assessment></app-add-communication-assessment>
    </p>


    <p *ngSwitchCase="'42'">
      <app-view-communication-assessment></app-view-communication-assessment>
    </p>



    <p *ngSwitchCase="'44'">
      <app-add-nurse-note></app-add-nurse-note>
    </p>


    <p *ngSwitchCase="'45'">
      <app-view-nurse-note></app-view-nurse-note>
    </p>

    <p *ngSwitchCase="'46'">
      <app-view-doctor-report></app-view-doctor-report>
    </p>


    <p *ngSwitchCase="'50'">
      <app-view-doctor-initial-evaluation></app-view-doctor-initial-evaluation>

    </p>

    <p *ngSwitchCase="'53'">
      <app-lab-test-chart></app-lab-test-chart>

    </p>
    <p *ngSwitchCase="'54'">
      <app-view-patient-prescription-by-nurse></app-view-patient-prescription-by-nurse>

    </p>
    <p *ngSwitchCase="'64'">
      <app-view-procedure></app-view-procedure>

    </p>
    <p *ngSwitchCase="'79'">
      <app-view-blood-sugar></app-view-blood-sugar>

    </p>
    <p *ngSwitchCase="'80'">
      <app-blood-sugar-chart></app-blood-sugar-chart>

    </p>
    <!-- <p *ngSwitchCase="'54'">
        <app-add-blood-transfusion-consent></app-add-blood-transfusion-consent>

      </p> -->
    <!-- <p *ngSwitchCase="'55'">
        <app-view-blood-transfusion-consent></app-view-blood-transfusion-consent>

      </p> -->



    <!-- <p *ngSwitchCase="'60'">
        <app-add-disclosure-and-consent></app-add-disclosure-and-consent>

      </p> -->
    <!-- <p *ngSwitchCase="'61'">
        <app-view-disclosure-and-consent></app-view-disclosure-and-consent>

      </p> -->


    <p *ngSwitchCase="'66'">
      <app-add-mri></app-add-mri>
    </p>

    <p *ngSwitchCase="'67'">
      <app-view-mri></app-view-mri>
    </p>



    <p *ngSwitchDefault>

      <app-nurse-patient-details></app-nurse-patient-details>
    </p>







  </span>

  <!-- </div>
     </div> -->
</div>