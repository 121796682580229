import { PatientinsuranceService } from './../../services/patientinsurance.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-add-patient-insurance',
  templateUrl: './add-patient-insurance.component.html',
  styleUrls: ['./add-patient-insurance.component.css'],
})
export class AddPatientInsuranceComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();

  id = 0;
  imageUrl: string;
  image2Url: string;
  isSuccess = false;
  constructor(
    private route: ActivatedRoute,
    private patientInsuranceService: PatientinsuranceService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }

  ptInsuranceFrm = this.fb.group({
    DoyouhaveInsurance: ['', Validators.required],
    PrimaryPolicyHolderName: ['', Validators.required],
    SecondaryPolicyHolderName: [''],
    InsuaranceName: ['', Validators.required],
    InsuaranceNo: ['', Validators.required],
    GroupNo: ['', Validators.required],
    InsuaranceExpiryDate: ['', Validators.required],
    InsImageFront: [''],
    InsImageBack: [''],
    Signature: ['', Validators.required],
  });

  get Signature() {
    return this.ptInsuranceFrm.get('Signature');
  }

  get DoyouhaveInsurance() {
    return this.ptInsuranceFrm.get('DoyouhaveInsurance');
  }
  get GroupNo() {
    return this.ptInsuranceFrm.get('GroupNo');
  }

  get PrimaryPolicyHolderName() {
    return this.ptInsuranceFrm.get('PrimaryPolicyHolderName');
  }

  get SecondaryPolicyHolderName() {
    return this.ptInsuranceFrm.get('SecondaryPolicyHolderName');
  }

  get InsuaranceName() {
    return this.ptInsuranceFrm.get('InsuaranceName');
  }
  get InsuaranceNo() {
    return this.ptInsuranceFrm.get('InsuaranceNo');
  }
  get InsuaranceExpiryDate() {
    return this.ptInsuranceFrm.get('InsuaranceExpiryDate');
  }
  get InsImageFront() {
    return this.ptInsuranceFrm.get('InsImageFront');
  }
  get InsImageBack() {
    return this.ptInsuranceFrm.get('InsImageBack');
  }

  private triggerFront: Subject<any> = new Subject();
  public webcamImageFront!: WebcamImage;
  private nextWebcamFront: Subject<any> = new Subject();
  sysImagefront = '';

  public getSnapshotFront(): void {
    this.triggerFront.next(void 0);
  }
  public captureImgFront(webcamImage: WebcamImage): void {
    this.webcamImageFront = webcamImage;
    this.sysImagefront = webcamImage!.imageAsDataUrl;
  }

  public get invokeObservableFront(): Observable<any> {
    return this.triggerFront.asObservable();
  }
  public get nextWebcamObservableFront(): Observable<any> {
    return this.nextWebcamFront.asObservable();
  }

  private triggerBack: Subject<any> = new Subject();
  public webcamImageBack!: WebcamImage;
  private nextWebcamBack: Subject<any> = new Subject();
  sysImageback = '';

  public getSnapshotBack(): void {
    this.triggerBack.next(void 0);
  }
  public captureImgBack(webcamImage: WebcamImage): void {
    this.webcamImageBack = webcamImage;
    this.sysImageback = webcamImage!.imageAsDataUrl;
  }

  InsuranceCardTabChange(event) {
    this.imageUrl = '';
    this.image2Url = '';

    this.sysImageback = '';
    this.sysImagefront = '';

    this.webcamImageFront = null;
    this.webcamImageBack = null;

    this.frontImageInput!.value = '';
    this.backImageInput!.value = '';
  }

  public get invokeObservableBack(): Observable<any> {
    return this.triggerBack.asObservable();
  }
  public get nextWebcamObservableBack(): Observable<any> {
    return this.nextWebcamBack.asObservable();
  }

  frontImageInput: any;
  backImageInput: any;
  image(e, input) {
    let formData = new FormData();
    let files = e.target.files;
    this.frontImageInput = input;
    formData.append('file', files[0], files[0].name);

    this.patientInsuranceService.imageUpload(formData).subscribe(
      (res) => {
        let insImg = environment.imgurl + res['path'];
        this.imageUrl = `<p><img src="${insImg}"/></p>`;
      },
      (err) => console.log(err)
    );
  }
  image2(e, input) {
    let formData = new FormData();
    let files = e.target.files;
    this.backImageInput = input;

    formData.append('file', files[0], files[0].name);

    this.patientInsuranceService.imageUpload(formData).subscribe(
      (res) => {
        let ins2Img = environment.imgurl + res['path'];
        this.image2Url = `<p><img src="${ins2Img}"/></p>`;
      },
      (err) => console.log(err)
    );
  }

  formSubmit() {
    let signature = this.ptInsuranceFrm.value['Signature'];
    let doyouhaveInsurance = this.ptInsuranceFrm.value['DoyouhaveInsurance'];
    let primaryPolicyHolderName =
      this.ptInsuranceFrm.value['PrimaryPolicyHolderName'];
    let secondaryPolicyHolderName =
      this.ptInsuranceFrm.value['SecondaryPolicyHolderName'];
    let insName = this.ptInsuranceFrm.value['InsuaranceName'];
    let insNo = this.ptInsuranceFrm.value['InsuaranceNo'];
    let groupNo = this.ptInsuranceFrm.value['GroupNo'];
    let insExpiryDate = this.ptInsuranceFrm.value['InsuaranceExpiryDate'];
    insExpiryDate = moment(insExpiryDate).format('MM/DD/YYYY');

    let InsImageFront = this.ptInsuranceFrm.value['InsImageFront'];
    let InsImageBack = this.ptInsuranceFrm.value['InsImageBack'];

    let body = {
      PatientId: this.id,
      DoyouhaveInsurance: doyouhaveInsurance,
      PrimaryPolicyHolderName: primaryPolicyHolderName,
      SecondaryPolicyHolderName: secondaryPolicyHolderName,
      InsuaranceName: insName,
      InsuaranceNo: insNo,
      GroupNo: groupNo,
      InsuaranceExpiryDate: insExpiryDate,
      InsImageFront: this.sysImagefront ? this.sysImagefront : this.imageUrl,
      InsImageBack: this.sysImageback ? this.sysImageback : this.image2Url,
      Signature: signature,
    };

    this.patientInsuranceService
      .registerPatientInsurance(body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.ptInsuranceFrm.reset();
        this.submitAction.emit('');
      });
  }

  facingMode: string = 'user'; //Set front camera
  public allowCameraSwitch = true;

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }
    return result;
  }
}
