import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Pharmacy } from 'src/app/Models/pharmacy.model';

import { PharmacylistService } from 'src/app/services/pharmacylist.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog-component';
import { MatDialog } from '@angular/material/dialog';
import { PharmacyService } from 'src/app/services/pharmacy.service';
import { PatientsPharmacy } from 'src/app/Models/patientspharmacy.model';

@Component({
  selector: 'app-add-patient-pharmacy',
  templateUrl: './add-patient-pharmacy.component.html',
  styleUrls: ['./add-patient-pharmacy.component.css'],
})
export class AddPatientPharmacyComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();
  @Output() selectedPatientIdsEmitter = new EventEmitter<any>();
  @Output() navigation = new EventEmitter<any>();
  displayedColumns: string[] = [
    'select',
    'PharmacyName',
    'PharmacyAddress',
    'PhoneNumber',
  ];
  id = 0;
  isSuccess = false;
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;
  pharmacyList: Pharmacy[];

  dataSource: MatTableDataSource<any>;
  pharmacyData;
  patientPharmacy: PatientsPharmacy[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private phService: PharmacylistService,
    private patientPharmacyService: PharmacyService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPharmacy();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getPharmacy() {
    this.phService.getPharmacys().subscribe((res) => {
      this.pharmacyList = res;
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
    });
  }

  selectParmacy(event, pharmacy) {
    this.pharmacyData = {
      PatientId: this.id,
      PharmacyId: pharmacy.PharmacyId,
      PharmacyName: pharmacy.PharmacyName,
      PharmacyAddress: pharmacy.PharmacyAddress,
      PhoneNumber: pharmacy.PhoneNumber,
      Email: pharmacy.Email,
      Status: true,
    };
    let index = this.pharmacyList?.findIndex(
      (ph) => ph?.PharmacyId === this.pharmacyData?.PharmacyId
    );
    if (event.checked) {
      if (index > -1) {
        this.pharmacyList[index] = this.pharmacyData;
      } else {
        this.pharmacyList.push(this.pharmacyData);
      }
    } else if (index > -1) {
      this.pharmacyList.splice(index, 1);
    }
  }
  emitSelectedIds() {
    this.selectedPatientIdsEmitter.emit(this.patientPharmacy);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  selectedPharmacy() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Add Pharmacy',
        content: 'Are you sure you want to add selected pharmacy',
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res === true) {
          this.pharmacyList.forEach((pharmacy) => {
            this.patientPharmacyService
              .registerPatientsPharmacy(pharmacy)
              .subscribe((res) => {
                this.isSuccess = true;
              });
          });
          setTimeout(() => {
            this.navigation.emit();
          }, 2000);
        }
      },
    });
  }
}
