<div class="conatiner mx-5 my-5">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-6" *ngFor="let item of evaluationList">

            <mat-card class="mat-elevation-z8 mt-3 ">
                <h2 class="clr">Doctor Initial Evaluation</h2>
                <p>{{item.DoctorName}}</p>
                <p class="float-right">Date : {{item.CreatedDate | date: 'short' }}</p>

                <hr>
                <mat-card-content>
                    <div class="row">
                        <div class="col-md-3">
                            Patient Name <p>{{item.PatientName}}</p>
                        </div>
                        <div class="col-md-3">
                            Patient D.O.B <p>{{item.PatientDOB}}</p>

                        </div>
                        <div class="col-md-3">
                            Patient Height <p>{{item.PatientHeight}}</p>

                        </div>
                        <div class="col-md-3">
                            Patient Height <p>{{item.PatientWeight}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            Blood Pressure <p>{{item.BloodPressure}}</p>
                        </div>
                        <div class="col-md-3">
                            Pulse <p>{{item.Pulse}}</p>

                        </div>
                        <div class="col-md-3">
                            Is Patient on Any Medication <p>{{item.IsPatientonAnyMedication}}</p>

                        </div>
                        <div class="col-md-3">
                            Is Patient Under Any Medical Treatment <p>{{item.IsPatientUnderAnyMedicalTreatment}}</p>
                        </div>
                    </div>
                    <mat-accordion>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    General
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <tr>
                                <th> Recent Weight Changes : </th>

                                <td>{{item.RecentWeightChanges ?'Yes':'No'}}</td>
                            </tr>
                            <tr>
                                <th> Chills : </th>
                                <td>{{item.Chills ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Fever : </th>
                                <td>{{item.Fever ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Weakness : </th>
                                <td>{{item.Weakness ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item.NoGeneralProblems ?'No Other Problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item.OtherGeneralProblems}}</td>

                            </tr>
                        </mat-expansion-panel>
                        <hr>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Eyes
                                </mat-panel-title>

                            </mat-expansion-panel-header>

                            <tr>
                                <th> Vision Change : </th>
                                <td>{{item.VisionChange ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Glassess/Contacts : </th>
                                <td>{{item.GlassessOrContacts ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Catracts : </th>
                                <td>{{item.Catracts ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Glaucoma : </th>
                                <td>{{item.Glaucoma ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item.NoEyeProblems ?'No Problemes':'Other Problems'}}</td>

                            </tr>
                            <tr>
                                <th>Other Problems : </th>
                                <td>{{item.OtherEyeProblems}}</td>

                            </tr>
                        </mat-expansion-panel>
                        <hr>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Ear,Nose,Throat
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <!-- Ear,Nose,Throat -->
                            <tr>
                                <th> HearingLoss : </th>
                                <td>{{item.HearingLoss ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> EarAche/Infection : </th>
                                <td>{{item.EarAcheOrInfection ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Ringing In Ear : </th>
                                <td>{{item.RingingInEar ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item.NoEarProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item.OtherEarProblems}}</td>

                            </tr>
                        </mat-expansion-panel>
                        <hr>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Cardio Vascular
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <tr>
                                <th> Chest Pain : </th>
                                <td>{{item.ChestPain ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Swelling In Legs : </th>
                                <td>{{item.SwellingInLegs ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Palpitations : </th>
                                <td>{{item.Palpitations ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item.NoCadioVascularProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item.OtherCadioVascularProblems}}</td>

                            </tr>
                        </mat-expansion-panel>
                        <hr>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Respiratory
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <tr>
                                <th> Shortness Of Breath : </th>
                                <td>{{item.ShortnessOfBreath ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Wheezing Or Asthma : </th>
                                <td>{{item.WheezingOrAsthma ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Frequent Cough : </th>
                                <td>{{item.FrequentCough ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item.NoRespiratoryProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item.OtherRespiratoryProblems}}</td>

                            </tr>
                        </mat-expansion-panel>
                        <hr>

                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Gastrointestinal
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <tr>
                                <th> Acid Reflex : </th>
                                <td>{{item.AcidReflex ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Nausea Or Vomitimng : </th>
                                <td>{{item.NauseaOrVomitimng ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Abdominal Pain : </th>
                                <td>{{item.AbdominalPain ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item.OtherGastricProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item.OtherRespiratoryProblems}}</td>

                            </tr>
                        </mat-expansion-panel>
                        <hr>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Muskulatal
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <tr>
                                <th> Muscle Aches : </th>
                                <td>{{item.MuscleAches ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Swelling Of Joints : </th>
                                <td>{{item.SwellingOfJoints ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Stiffness In Joints : </th>
                                <td>{{item.StiffnessInJoints ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item.NoMuskularProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item.OtherMuskularProblems}}</td>

                            </tr>
                        </mat-expansion-panel>
                        <hr>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Skin
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <tr>
                                <th> Rash : </th>
                                <td>{{item.Rash ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Ulcers : </th>
                                <td>{{item.Ulcers ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Abnormal Scars : </th>
                                <td>{{item.AbnormalScars ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item.NoSkinProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item.OtherSkinProblems}}</td>

                            </tr>
                        </mat-expansion-panel>
                        <hr>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Neurological
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <tr>
                                <th> Headaches : </th>
                                <td>{{item.Headaches ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Dizziness : </th>
                                <td>{{item.Dizziness ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Numbness , Tingling , Loss of Sensation : </th>
                                <td>{{item.NumbnessTinglingLossofSensation ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item.NoNeurologicalProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item.OtherNeurologicalProblems}}</td>

                            </tr>
                        </mat-expansion-panel>
                        <hr>

                        <hr>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Psychiatric
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <tr>
                                <th> Depression : </th>
                                <td>{{item.Depression ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Nervousness : </th>
                                <td>{{item.Nervousness ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Anxiety : </th>
                                <td>{{item.Anxiety ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Mood Swings : </th>
                                <td>{{item.MoodSwings ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item.NoPsychatricProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item.OtherPsychatricProblems}}</td>

                            </tr>
                        </mat-expansion-panel>
                        <hr>

                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Endocrine
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <tr>
                                <th> Exessive Thirst Or Hunger : </th>
                                <td>{{item.ExessiveThirstOrHunger ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Hot Cold Intolerance : </th>
                                <td>{{item.HotColdIntolerance ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Hot Flashes : </th>
                                <td>{{item.HotFlashes ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> None : </th>
                                <td>{{item.NoEndocrine ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item.OtherEndocrine}}</td>

                            </tr>
                        </mat-expansion-panel>
                        <hr>


                    </mat-accordion>
                </mat-card-content>
                <button class="" [routerLink]="['/updateDctorEvaluation/',item.DoctorInitialEvaluationId]"
                    [disabled]="CurrentDoctorId != item.DoctorId" color="primary" mat-mini-fab><mat-icon>edit</mat-icon>
                </button>
                <button class="float-right ml-5" (click)="delete(item.DoctorInitialEvaluationId)" color="accent"
                    [disabled]="CurrentDoctorId != item.DoctorId" mat-mini-fab><mat-icon>delete</mat-icon> </button>

            </mat-card>
        </div>
    </div>
</div>